import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useHistory, useLocation, useRouteMatch } from 'react-router';
import { hasCustomerDetails, existsButNeedsMobileNumber } from './AppController';
import BasketController from './features/basket/BasketController';
import CheckoutController from './features/checkout/CheckoutController';
import MenuController from './features/menu/MenuController';
import PaymentController from './features/payment/PaymentController';
import PasswordResetConfirmation from './features/checkout/pages/PasswordResetConfirmation';
import OrderSummary from './features/payment/pages/OrderSummary';
import OutOfStockModal from './components/modals/OutOfStockModal';
import ForgottenPassword from './features/checkout/pages/ForgottenPassword';
import {
  useAddBundleToBasketMutation, useAddToBasketMutation, useCheckOutOfStockMutation, useQuickAddItemAndRewardMutation, useQuickAddItemMutation,
  useQuickRemoveItemMutation, useQuickSelectItemMutation, useRemoveBundleFromBasketMutation, useRemoveFromBasketMutation
} from './services/basket.api';
import { applyDefaultSubProducts, getCurrentCalculatedCostForItem, getItemIndexByUniqueId, getOptionGtmParameters } from './helpers/basketHelpers';
import { historyMW } from './helpers/routingHelpers';
import { DeliveryTimeUpdateModalWrapper } from './helpers/modalHelpers';
import { setUpdatedDeliveryTime } from './store/sessionSlice';
import hash from 'object-hash';
import { useLazyGetOrderForTodayQuery } from './services/order.api';
import LoaderBlack from '../src/img/common/Loader_360x360.gif';
import { gtmEvent } from './helpers/commonHelpers';
import { config } from './config.js';

const brand = config.BRAND;

const OrderJourneyController = ({ setShowChangeRestaurantModal, refreshBasket, setDisablePaymentBackButton, disablePaymentBackButton }) => {
  const { path } = useRouteMatch();
  const { customer, restaurant, inApp, collectionTime, deliveryTimeUpdated } = useSelector(state => state.session);
  const basket = useSelector(state => state.basket);
  const activeCategory = useSelector(state => state.menu.activeCategory);
  const dispatch = useDispatch();
  const [removeFromBasket, { isLoading: isRemovingFromBasket }] = useRemoveFromBasketMutation();
  const [addToBasket, { isLoading: isAddingToBasket }] = useAddToBasketMutation();
  const [quickAddItemAndReward, { isLoading: isQuickAddingToBasket }] = useQuickAddItemAndRewardMutation();
  const [quickAddItem] = useQuickAddItemMutation();
  const [quickRemoveItem] = useQuickRemoveItemMutation();
  const [checkOutOfStock] = useCheckOutOfStockMutation();
  const [addBundleToBasket, { isLoading: isAddingBundleToBasket }] = useAddBundleToBasketMutation();
  const [removeBundleFromBasket, { isLoading: isRemovingBundleFromBasket }] = useRemoveBundleFromBasketMutation();
  const [quickSelectItemMutation, { isLoading: isQuickSelectingItem }] = useQuickSelectItemMutation();
  const [getOrder, { data, isLoading: isOrderLoading, isFetching, isUninitialized }] = useLazyGetOrderForTodayQuery();
  const appApproved = useSelector(state => state.session?.customer?.appApproved);
  const loyalty = useSelector(state => state.session?.features.loyalty) && appApproved;
  const history = useHistory();
  const isUpdatingBasket =
    isRemovingFromBasket ||
    isAddingToBasket ||
    isAddingBundleToBasket ||
    isRemovingBundleFromBasket ||
    isQuickAddingToBasket ||
    isQuickSelectingItem;
  const { pathname, search } = useLocation();
  const query = new URLSearchParams(search);
  const [notification, setNotification] = useState({ visible: false, item: {} });

  const handleRemoveItem = async (item) => {
    if (!isUpdatingBasket) {
      let response;
      const body = { ...item, quantity: 1, restaurantId: restaurant?.id };

      var event = {
        channel: inApp ? 'App' : 'Web',
        currency: brand === 'PE' ? 'GBP' : 'EUR',
        clickAndCollectAction: basket.isDelivery ? 'Delivery_Removed_Items' : 'Collection_Removed_Items',
        value: basket.total,
      };
      if (customer?.customerSessionToken?.pizzaExpressId) {
        event['customerId'] = customer.customerSessionToken.pizzaExpressId;
      }

      const params = {
        item_id: body.id || body.customID,
        item_name: body.name,
        item_brand: brand,
        quantity: body.quantity
      };

      if (item.eposDiscountId) { // Bundle
        response = await removeBundleFromBasket(body);
        var priceOfRemovedBundle = 0;
        if (response?.data?.status === 'OK') {
          // Get the price of the removed bundle by subtracting response cost from previous cost
          const responseBundle = response.data.bundles.find(b => b.customID === body.customID);
          const bundleCost = responseBundle ? responseBundle.calculatedCost : body.calculatedCost;
          priceOfRemovedBundle = +(body.calculatedCost - bundleCost).toFixed(2);
        }
        // For bundles, add all of the choices to the gtm params
        const options = body.sections.flatMap(el =>
          el.products.map(pr => `${pr.product.name} x ${pr?.product?.quantity ? pr.product.quantity : 1}`)
        );
        options.forEach((option, index) => {
          params[`item_category${index + 1}`] = option;
        });
        params['price'] = priceOfRemovedBundle;

      } else {  // Normal item
        response = await removeFromBasket([body]);
        params['item_category'] = body.categoryName;
        var priceOfRemovedItem = 0;
        if (body.customID && response?.data?.status === 'OK') {  // When it's a custom item, add all of the choices to the gtm params
          getOptionGtmParameters(body, params);
          priceOfRemovedItem = +(
            body.calculatedCost -
            (response.data.items.find(i => i.customID === body.customID)?.calculatedCost ?? 0)
          ).toFixed(2);
        } else if (response?.data?.status === 'OK') {
          priceOfRemovedItem = +(
            body.calculatedCost -
            (response.data.items.find(i => i.id === body.id)?.calculatedCost ?? 0)
          ).toFixed(2);
        }
        params['price'] = priceOfRemovedItem;
      }
      if (response?.data?.status === 'OK') {
        refreshBasket();
        var items = [];
        items.push(params);
        event['items'] = items;
        gtmEvent('remove_from_cart', event);
      }
    }
  };

  const handleOutOfStockRemovedItems = async () => {
    const params = { channel: inApp ? 'App' : 'Web' };
    if (customer?.customerSessionToken?.pizzaExpressId) {
      params.customerId = customer.customerSessionToken.pizzaExpressId;
    }

    gtmEvent(basket.isDelivery ? 'Delivery_Remove_Items' : 'Collection_Remove_Items', params);
    historyMW.replace('/basket', basket.isDelivery, history);
    refreshBasket();
  };

  const handleAddItem = async (item, custom) => {
    if (!isUpdatingBasket) {
      let response;
      item = applyDefaultSubProducts(item);
      if (custom) {
        item = { ...item, customID: hash(item) };
      }
      const body = { ...item, quantity: 1, restaurantId: restaurant?.id };
      if (item.eposDiscountId) {
        response = await addBundleToBasket(body);
      } else {
        response = await addToBasket([body]);
      }
      if (response?.data?.status === 'OK') {
        setNotification({ visible: true, item });
        refreshBasket();

        var priceOfAddedItem = 0;
        if (item.eposDiscountId) { // Bundles
          priceOfAddedItem = (response.data.bundles.find(b=> b.customID === body.customID).calculatedCost - 
          body.calculatedCost)
          .toFixed(2);
        } else { // Normal Item
          if (body.customID) {
            priceOfAddedItem = (response.data.items.find(i => i.customID === body.customID).calculatedCost -
            body.calculatedCost)
            .toFixed(2);
          }
          else {
            priceOfAddedItem = (response.data.items.find(i => i.id === body.id).calculatedCost -
            body.calculatedCost)
            .toFixed(2);
          }
        }

        var event = {
          channel: inApp ? 'App' : 'Web',
          currency: brand === 'PE' ? 'GBP' : 'EUR',
          clickAndCollectAction: basket.isDelivery ? 'Delivery_Add_Items' : 'Collection_Add_Items',
          value: basket.total,
        };
        if (customer?.customerSessionToken?.pizzaExpressId) {
          event['customerId'] = customer.customerSessionToken.pizzaExpressId;
        }
        const params = {
          'item_id': body.customID || body.id,
          'item_name': body.name,
          'price': priceOfAddedItem,
          'item_brand': brand,
          'item_category': activeCategory,
          'quantity': body.quantity
        };
        var items = [];
        items.push(params);
        event['items'] = items;

        gtmEvent('add_to_cart', event);
      }
    }
  };

  const handleQuickAddItemAndReward = async (productId, portionTypeId, thirdPartyRewardTypeId) => {
    let response = await quickAddItemAndReward({
      productId,
      portionTypeId,
      thirdPartyRewardTypeId
    });

    if (response.data) {
      await handleAddItem(response.data);
      await quickSelectItemMutation({
        productId,
        portionTypeId,
        thirdPartyRewardTypeId
      });
    }
  };

  window.quickAdd = (productId, portionId) => quickAddItem({ productId, portionId });
  window.quickRemove = (productId, portionId) => quickRemoveItem({ productId, portionId });
  window.quickRoute = (path, isReplace) => isReplace ? historyMW.replace(path, basket.isDelivery, history) : historyMW.push(path, basket.isDelivery, history);
  window.getBasket = () => ({ items: basket.items, bundles: basket.bundles });
  window.checkOutOfStock = async (productIds) => await checkOutOfStock(productIds);

  const handleUpdatedDeliveryModalClose = () => {
    dispatch(setUpdatedDeliveryTime(false));
  };

  const getOrderConfirmation = async () => {
    if (!basket.order && inApp && search) {

      const orderId = query.get('ord');
      const restaurantId = query.get('rid');
      if (!orderId) return;
      await getOrder({ id: orderId, restaurantId: restaurantId });
    }
  };

  useEffect(() => {
    getOrderConfirmation();
  }, []);

  return (
    <>
      <Route path={`${path}/menu/:restaurantId?`}>
        <MenuController
          onAddItem={handleAddItem}
          onRemoveItem={handleRemoveItem}
          notification={notification}
          setNotification={setNotification}
          refreshBasket={refreshBasket}
        />
      </Route>
      <Route path={`${path}/checkout`}>
        <Route path={`${path}/checkout/details`}>
          {!hasCustomerDetails(basket)
            ? <Redirect to={`${path}/checkout/login`} />
            : !basket?.items?.length && !basket?.bundles?.length
              ? <Redirect to={`${path}/menu/${restaurant?.id}`} />
              : <CheckoutController onRemoveItem={handleOutOfStockRemovedItems} />}
        </Route>
        <Route path={`${path}/checkout`}>
          <CheckoutController onAddItem={handleQuickAddItemAndReward} onRemoveItem={handleOutOfStockRemovedItems} />
        </Route>
      </Route>

      <Route path={`${path}/payment`}>
        {!basket?.items?.length && !basket?.bundles?.length
          ? !restaurant?.id
            ? <Redirect to="/" />
            : <Redirect to={`${path}/menu/${restaurant?.id}`} />
          : !hasCustomerDetails(basket, customer)
            ? existsButNeedsMobileNumber(customer)
              ? <Redirect to={`${path}/checkout/verifyMobile`} />
              : <Redirect to={`${path}/checkout/login`} />
            : !collectionTime && !basket.delivery ? <Redirect to={`${path}/checkout/details`} />
              : basket.delivery && !basket.delivery.deliveryAddress.addressLine1 ? <Redirect to={`${path}/checkout/details`} />
                : <PaymentController setDisablePaymentBackButton={setDisablePaymentBackButton} disablePaymentBackButton={disablePaymentBackButton} onRemoveItem={handleOutOfStockRemovedItems} />}
      </Route>

      <Route path={`${path}/basket`}>
        {!restaurant?.id
          ? <Redirect to="/" />
          : <BasketController
            onAddItem={handleAddItem}
            onRemoveItem={handleRemoveItem}
            selectingRewards={false}
          />}
      </Route>
      <Route path={`${path}/loyalty`}>
        {!restaurant?.id
          ? <Redirect to="/" />
          : <BasketController
            onAddItem={handleAddItem}
            onRemoveItem={handleRemoveItem}
            selectingRewards={true}
          />}

      </Route>
      <Route exact path={`${path}/forgottenPassword`} component={ForgottenPassword} />
      <Route exact path={`${path}/passwordResetConfirmation`} component={PasswordResetConfirmation} />
      <Route exact path={`${path}/order/confirmation`}>
        {
          basket.order || data ? <OrderSummary
            isRouted={!!query.get('ord')}
            loyalty={loyalty}
            onChangeRestaurant={() => setShowChangeRestaurantModal(true)}
            order={basket.order || data}
            getOrder={getOrder}
          />
            : isOrderLoading || isFetching || (isUninitialized && search)
              ? <div style={{ alignContent: 'center', flexDirection: 'column', display: 'flex', alignItems: 'center', paddingTop: '128px', paddingBottom: '128px' }}>
                <img src={LoaderBlack} style={{ width: 64, height: 64 }} />
              </div>
              : <Redirect to="/" />
        }
      </Route>
      <DeliveryTimeUpdateModalWrapper
        onClose={handleUpdatedDeliveryModalClose}
        show={pathname !== '/collection' && pathname !== '/delivery' && deliveryTimeUpdated}
        restaurantId={restaurant?.id}
        deliveryEstimate={basket?.delivery?.deliveryQuote?.duration}
        inApp={inApp}
      />
    </>
  );
};

export default OrderJourneyController;